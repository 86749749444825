import React, { useState } from "react"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import SEO from "../components/seo"
import LayoutNoStripe from "../components/layoutNoStripe"
import LoginForm from '../components/formLogin'
import {
  Section,
  SectionHeading,
  SectionHeaderLedeDiv,
} from "../styledComponents/section"
import { StyledSignUpForm } from "../styledComponents/signUpForm"
import SignupNewsletterForm from "../components/formNewsletterSignup"

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const Login = ({ location }) => {
  const identity = useIdentityContext()
  const navigateTarget = '/my-account'
  const [isCookieSet, setIsCookieSet] = useState(true)

  const createCookie = (value) => {
    document.cookie = value + `=true; path=/; host=${process.env.GATSBY_DOMAIN_NAME
      }; ${process.env.GATSBY_SECURE_COOKIE ? "secure;" : ""}`
    setIsCookieSet(true)
  }


  return (
    <>
    <LayoutNoStripe location={location} hideNewsletterSignUp={true}>
      <SEO 
        title="Energy Healing &amp; Chakra Balancing: Login" 
        description="Login to get access to the meditations programs and audios."
        url=""
      />
             <StyledSignUpForm>
            <SignupNewsletterForm createCookie={createCookie} />
          </StyledSignUpForm>
    </LayoutNoStripe>
    </>
  )
}


export default Login