import React, { useState, useEffect } from 'react'
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { useForm } from "react-hook-form"
import { navigate, Link } from 'gatsby'
import {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper
} from "../styledComponents/contact"
import { Section } from '../styledComponents/section'
import { StyledButton, StyledButtonViewPassword } from "../styledComponents/button"
import Icon from "../components/icon"

const LoginForm = ({navigateTarget, products}) => {
  const identity = useIdentityContext()
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [formError, setFormError] = useState(false)
  const [loggingIn, setLoggingIn] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  }

  // When cold-loading a PrivateContent page, the user can get redirected to
  // /login, but once the User hydrates from LocalStorage, we want to send them
  // back ASAP
  useEffect(() => {
    navigateTarget && identity.user && navigate(navigateTarget)
  }, [navigateTarget, identity.user])

  const onSubmit = async (data) => {
    setLoggingIn(true)
    setFormError(false)

    await identity
      .login({ email: data.email, password: data.password })
      .then(() => {
        setLoggingIn(false)
        navigateTarget && navigate(navigateTarget)
      })
      .catch(e => {
        setLoggingIn(false)
        setFormError(e.message)
      })
  }

  return (
    identity.user
      ? <> </>
      : identity.provisionalUser ?
        <Section>
          <p>Your account has not yet been confirmed. Please check your email.</p>
        </Section>
        : 
          <StyledForm  onSubmit={handleSubmit(onSubmit)}>
           
              <StyledFieldset>
                <StyledFormElementWrapper>
                  <StyledLabel htmlFor="email">
                    <StyledLabelHeader
                      required
                      isEmpty={errors.email}
                    >
                      Email
                    </StyledLabelHeader>
                    <StyledInput
                      id="email"
                      name="email"
                      type="text"
                      // onChange={handleChange}
                      // value={values.email}
                      // onBlur={handleBlur}
                      required
                      {...register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                    className={`${loggingIn && 'disabled'}`}
                    />
                  </StyledLabel>
                  {errors.email ? (
                    <span className="error-message">Email is required, use correct format</span>
                  ) : null}
                </StyledFormElementWrapper>

                <StyledFormElementWrapper>
                  <StyledLabel htmlFor="password">
                    <StyledLabelHeader
                      required
                      isEmpty={errors.password}
                    >
                      Password
                    </StyledLabelHeader>
                    <StyledInput
                        {...register('password',{ required: true })}
                        className={`${loggingIn && 'disabled'}`}
                        name="password"
                        type={passwordShown ? "text" : "password"}
                      />
                      <StyledButtonViewPassword onClick={togglePassword} ><Icon name="eye"/></StyledButtonViewPassword>
                  </StyledLabel>
                  {errors.password ? (
                    <span className="error-message">Password is required</span>
                  ) : null}
                </StyledFormElementWrapper>
                
                {products &&
                    <StyledInput
                      name="product"
                      value={products}
                      type="hidden"
                      {...register('product',{ required: false })}
                    />
                  }
              <StyledButton
                className={`${loggingIn && 'disabled'}`}
                type="submit">
                Log in
              </StyledButton>
              {/* <p className="forgot-password"><Link to="/forgot-password/">
                Forgot Password?
              </Link>
              </p> */}
              {formError && <p className="text-red-500 text-xs italic">{formError}</p>}
            </StyledFieldset>
            </StyledForm>
    

  )
}

export default LoginForm